/* You can add global styles to this file, and also import other style files */
/* TODO(Rusty): this should be merged in main.css*/

/***** AG-GRID ******/
/* Core Data Grid CSS */
@import '@ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import '@ag-grid-community/styles/ag-theme-quartz.css';

/***** REPORTS *****/
@import "@mescius/activereportsjs/styles/ar-js-ui.css";
@import "@mescius/activereportsjs/styles/ar-js-viewer.css";
@import "@mescius/activereportsjs/styles/ar-js-designer.css";
@import "~codemirror/lib/codemirror.css";
@import "~codemirror/theme/idea.css";
@import "~codemirror/theme/base16-light.css";
@import "~codemirror/addon/fold/foldgutter.css";
@import "~codemirror/addon/lint/lint.css";
@import 'node_modules/wavelength-ui/styles/styles.scss';
@import 'ngx-toastr/toastr';

.datex-tooltip,
.datex-control-tooltip {
  border: 1px solid black;
  margin: 3px;
}

.fieldset-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 8px 0;
}

.fieldset-info {
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
}

.fieldset-button {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
}